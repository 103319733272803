import Vue from 'vue';
import App from './App.vue';
import './assets/base.css';
import router from './router';
import store from './store';

// const vConsole = new VConsole();
Vue.config.productionTip = false;
// Vue.use(vConsole)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
