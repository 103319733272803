<template>
  <div class="home">
    <div :class="`misc ${playState ? 'miscAm' : ''}`" @click="playMisc">
      <i><img src="../assets/icons.png" alt="" width="50%"></i>
    </div>
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <h1 class="logoAm"><img src="../assets/slide1logo.png" alt=""></h1>
          <div class="text">
            <div class="left leftAm"><img src="../assets/text2.png" alt="" /></div>
            <div class="right">
              <p class="rightAm1"><img src="../assets/slide1Text2.png" alt=""/></p>
              <p class="rightAm2"><img src="../assets/slide1Text3.png" alt="" /></p>
            </div>
          </div>
          <div class="titles titlesAm">
            <img src="../assets/slide1Title.png" alt="">
          </div>
          <div class="theme themeAm">
            <img src="../assets/slide1Index.png" alt="">
          </div>
          <div class="site siteAm">
            <p>12月28日（周六）</p>
            <p>长沙世茂希尔顿酒店4楼世茂1+2厅</p>
          </div>
          <div class="bgs">
            <img src="../assets/slideBgs.png" alt="" class="bgsAm">
            <i class="b1Am"><img src="../assets/b1.png" width="100%" alt=""></i>
            <i class="b2Am"><img src="../assets/b2.png" width="100%" alt=""></i>
            <i class="b3Am"><img src="../assets/b3.png" width="100%" alt=""></i>
          </div>
        </div>
        <div :class="`swiper-slide slide2 ${animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`">
          <div class="logo">
            <img src="../assets/slide1logo.png" alt="">
          </div>
          <div class="title">
            <img src="../assets/sl2Title.png" alt="">
          </div>
          <div class="contentBox">
            <div class="content">
              <div class="contentText">
                <p>尊敬的理事：</p>
                <p>您好！</p>
                <p>时光无恙，感恩相逢。香港城市大学EMBA(中文)校友会将于2024年12月28日下午15:00在湖南长沙世茂希尔顿酒店四楼世茂1+2厅举行香港城市大学EMBA(中文)校友会第四届理事大会就职典礼。</p>
                <p>届时，我们将隆重举行第四届理事会和服务管理团队成员的聘书及牌匾颁发仪式，这也将是属于您的盛大就职典礼。此刻，我们怀着满心热忱，诚挚地邀请每一位理事拨冗莅临。</p>
                <p>愿我们携手共鉴这荣耀加身的时刻，一同开启新征程，践行使命，共铸辉煌！</p>
                <p>香港城市大学EMBA(中文)校友会</p>
                <p>2024年12月</p>
              </div>
            </div>
          </div>
          <div class="bgs">
            <img src="../assets/slideBgs2.png" alt="">
          </div>
        </div>
        <div :class="`swiper-slide slide3 ${animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`">
          <div class="logo">
            <img src="../assets/slide1logo.png" alt="">
          </div>
          <div class="title">
            <p><span>活动流程</span></p>
          </div>
          <div class="contentBox">
            <div class="content">
              <div class="contentText">
                <h1>· 2024-12-28 ·</h1>
                <div class="lop">
                  <div class="left">
                    <p>15:00 — 15:30</p>
                    <p>15:30 — 15:35</p>
                    <p>15:35 — 15:40</p>
                    <p>15:40 — 17:00</p>
                    <p>17:00 — 17:10</p>
                    <p>17:10 — 17:20</p>
                  </div>
                  <div class="center">
                    <p><i></i></p>
                    <p><i></i></p>
                    <p><i></i></p>
                    <p><i></i></p>
                    <p><i></i></p>
                    <p><i></i></p>
                  </div>
                  <div class="right">
                    <p>暖场签到</p>
                    <p>领导致辞</p>
                    <p>颁发荣誉证书</p>
                    <p>颁发聘书</p>
                    <p>授牌仪式</p>
                    <p>理事大合影</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bgs">
            <img src="../assets/slideBgs2.png" alt="">
          </div>
        </div>
        <div :class="`swiper-slide slide4 ${animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`">
          <div class="logo">
            <img src="../assets/slide1logo.png" alt="">
          </div>
          <div class="title">
            <p><span>历届<br>就职典礼回顾</span></p>
          </div>
          <div class="content">
            <div class="po1">
              <img src="../assets/po1s.png" alt="" width="100%">
            </div>
            <div class="po2">
              <img src="../assets/pos2.png" alt="" width="100%">
            </div>
            <div class="po3">
              <img src="../assets/pos3.png" alt="" width="100%">
            </div>
            <div class="po4">
              <img src="../assets/bottom.png" alt="" width="100%">
            </div>
          </div>
          <div class="bgs">
            <img src="../assets/slideBgs2.png" alt="">
          </div>
        </div>
        <div :class="`swiper-slide slide4 slide7 ${animationList.indexOf('slide7Animation') > -1 ? 'slide7Animation' : ''}`">
          <div class="logo">
            <img src="../assets/slide1logo.png" alt="">
          </div>
          <div class="title">
            <p><span>荣耀授牌 邀君莅临</span></p>
          </div>
          <div class="content">
            <div class="po1">
              <img src="../assets/po17.png" alt="" width="100%">
            </div>
            <div class="po2">
              <img src="../assets/po27.png" alt="" width="100%">
            </div>
            <div class="po3">
              <img src="../assets/po37.png" alt="" width="100%">
            </div>
            <div class="po4">
              <img src="../assets/bottom.png" alt="" width="100%">
            </div>
          </div>
          <div class="bgs">
            <img src="../assets/slideBgs2.png" alt="">
          </div>
        </div>
        <div :class="`swiper-slide slide5 ${animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`">
          <div class="logo">
            <img src="../assets/slide1logo.png" alt="">
          </div>
          <div class="title">
            <p><span>时间地点报名方式</span></p>
          </div>
          <div class="contentBox">
            <div class="content">
            <div class="contentText">
              <div class="cs1">
                <div class="img"><img src="../assets/sli51.png" alt="" width="100%"></div>
                <p>
                  <span>12.28</span>
                  <span>
                    <b>周六</b>
                    <i>15:00-17:00</i>
                  </span>
                </p>
              </div>
              <div class="cs2">
                <div class="img"><img src="../assets/sli52.png" alt="" width="100%"></div>
                <p>长沙世茂希尔顿酒店4楼世茂1+2厅</p>
                <p>（地址:岳麓区潇湘北路三段1100号）</p>
              </div>
              <div class="qrcode">
                <img src="../assets/sli53.png" alt="" width="100%">
              </div>
              <div class="action">
                <button><img src="../assets/sli56.png" width="100%" alt=""></button>
                <p :class="`${num >= 3 ? '' : 'am'}`" ref="ams">（注：如你已报名年会，无需重复报名）</p>
              </div>
            </div>
          </div>
          </div>
          <div class="tips">
            <span><img src="../assets/sli54.png" alt="" width="100%"></span>
            <p>如有任何疑问或需要帮助</p>
            <p>请联系校友会秘书处</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Howl } from 'howler';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import wx from 'weixin-js-sdk';
import assetsBgm from '../assets/M500003zJo260IelBy.mp3';
export default {
  name: 'HomeView',
  data() {
    return {
      animationList: [],
      animationObj: {
        0:'slide1Animation',
        1:'slide2Animation',
        2:'slide3Animation',
        3:'slide4Animation',
        4:'slide7Animation',
        5:'slide5Animation',
      },
      playState: true,
      num:0,
      soundBgm: null,
    }
  },
  mounted() {
    const that = this;
    var mySwiper = new Swiper ('.swiper', {
      initialSlide: 0,
      direction: 'vertical', // 垂直切换选项
      loop: false, // 循环模式选项
      on:{
        slideChange: function(e){
          if (that.animationList.indexOf(that.animationObj[this.activeIndex]) == -1) {
            that.animationList.push(that.animationObj[this.activeIndex])
            if (this.activeIndex == 5) {
              setTimeout(function(){
                let time = setInterval(function () {
                  if (that.num < 3) {
                    that.num++
                    console.log(that.num)
                  } else {
                    clearInterval(time)
                  }
                }, 1000)
              }, 3200)
            }
          }
        },
      },
  })
  this.getWxConfig();
  },
  methods: {
    playMisc() {
      if (this.soundBgm.playing()) {
        this.soundBgm.pause();
        this.playState = false;
      } else {
        this.playState = true;
        this.soundBgm.play();
      }
    },
    onAudioLoaded() {
      const that = this;
      that.soundBgm = new Howl({
        src: [assetsBgm],
        loop: true,
        preload: true,
      })
      // 音频资源 load 之后通过微信桥接触发播放
      that.soundBgm.on('load',()=>{
        console.log('音乐准备完毕')
        window.WeixinJSBridge && window.WeixinJSBridge.invoke('getNetworkType', {},  ()=> {
          that.soundBgm.play();
        }, false);
      })
    },
    getWxConfig() {
      const url = encodeURIComponent('http://nodetest.cn/')
      axios({
        url: '/get-wx-config?url=' + url,
        method: 'GET', // 默认为get
      }).then(value=>{
        // 获取请求成功的结果
        this.setWxConfig(value.data)
      }).catch(error=>{
      // 获取请求发送失败的结果
      console.log('error', error)
      })
    },
    setWxConfig(data) {
      const that = this
      wx.config({
        debug: false, // 开启调试模式,
        appId: data.appId, //必填，企业号的唯一标识，此处企业号corpid
        timestamp: data.timestamp, //必填，生成签名的时间戳
        nonceStr: data.nonceStr, //必填，生成签名的随机串
        signature: data.signature, //必填，签名，见附录1
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], //必填，需要使用的JS接口列表JS接口列表见附录2
      })
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        that.onAudioLoaded();
        wx.updateAppMessageShareData({
          title: '理事就职典礼【邀请函】', // 分享标题
          desc: '香港城市大学EMBA(中文)\n校友会第四届理事会\n荣誉 责任 收获', // 分享描述
          link: 'http://nodetest.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://nodetest.cn/logo.png', // 分享图标
          success: function () {
            // 设置成功
            console.log('分享给朋友设置成功')
          },
          fail: function (err) {
            // 设置失败
            console.log(err)
          }
        })
        wx.updateTimelineShareData({ 
          title: '理事就职典礼【邀请函】', // 分享标题
          link: 'http://nodetest.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://nodetest.cn/logo.png', // 分享图标
          success: function () {
            // 设置成功
            console.log('分享到朋友圈设置成功')
          }
        })
      });
    }
  },
  components: {
  },
};
</script>
<style>
@import url(../assets/style.css);
@import url(../assets/animation.css);
</style>
