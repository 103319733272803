var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{class:`misc ${_vm.playState ? 'miscAm' : ''}`,on:{"click":_vm.playMisc}},[_vm._m(0)]),_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._m(1),_c('div',{class:`swiper-slide slide2 ${_vm.animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{class:`swiper-slide slide3 ${_vm.animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)]),_c('div',{class:`swiper-slide slide4 ${_vm.animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`},[_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]),_c('div',{class:`swiper-slide slide4 slide7 ${_vm.animationList.indexOf('slide7Animation') > -1 ? 'slide7Animation' : ''}`},[_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]),_c('div',{class:`swiper-slide slide5 ${_vm.animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`},[_vm._m(18),_vm._m(19),_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"contentText"},[_vm._m(20),_vm._m(21),_vm._m(22),_c('div',{staticClass:"action"},[_vm._m(23),_c('p',{ref:"ams",class:`${_vm.num >= 3 ? '' : 'am'}`},[_vm._v("（注：如你已报名年会，无需重复报名）")])])])])]),_vm._m(24)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',[_c('img',{attrs:{"src":require("../assets/icons.png"),"alt":"","width":"50%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide1"},[_c('h1',{staticClass:"logoAm"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"left leftAm"},[_c('img',{attrs:{"src":require("../assets/text2.png"),"alt":""}})]),_c('div',{staticClass:"right"},[_c('p',{staticClass:"rightAm1"},[_c('img',{attrs:{"src":require("../assets/slide1Text2.png"),"alt":""}})]),_c('p',{staticClass:"rightAm2"},[_c('img',{attrs:{"src":require("../assets/slide1Text3.png"),"alt":""}})])])]),_c('div',{staticClass:"titles titlesAm"},[_c('img',{attrs:{"src":require("../assets/slide1Title.png"),"alt":""}})]),_c('div',{staticClass:"theme themeAm"},[_c('img',{attrs:{"src":require("../assets/slide1Index.png"),"alt":""}})]),_c('div',{staticClass:"site siteAm"},[_c('p',[_vm._v("12月28日（周六）")]),_c('p',[_vm._v("长沙世茂希尔顿酒店4楼世茂1+2厅")])]),_c('div',{staticClass:"bgs"},[_c('img',{staticClass:"bgsAm",attrs:{"src":require("../assets/slideBgs.png"),"alt":""}}),_c('i',{staticClass:"b1Am"},[_c('img',{attrs:{"src":require("../assets/b1.png"),"width":"100%","alt":""}})]),_c('i',{staticClass:"b2Am"},[_c('img',{attrs:{"src":require("../assets/b2.png"),"width":"100%","alt":""}})]),_c('i',{staticClass:"b3Am"},[_c('img',{attrs:{"src":require("../assets/b3.png"),"width":"100%","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../assets/sl2Title.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"contentText"},[_c('p',[_vm._v("尊敬的理事：")]),_c('p',[_vm._v("您好！")]),_c('p',[_vm._v("时光无恙，感恩相逢。香港城市大学EMBA(中文)校友会将于2024年12月28日下午15:00在湖南长沙世茂希尔顿酒店四楼世茂1+2厅举行香港城市大学EMBA(中文)校友会第四届理事大会就职典礼。")]),_c('p',[_vm._v("届时，我们将隆重举行第四届理事会和服务管理团队成员的聘书及牌匾颁发仪式，这也将是属于您的盛大就职典礼。此刻，我们怀着满心热忱，诚挚地邀请每一位理事拨冗莅临。")]),_c('p',[_vm._v("愿我们携手共鉴这荣耀加身的时刻，一同开启新征程，践行使命，共铸辉煌！")]),_c('p',[_vm._v("香港城市大学EMBA(中文)校友会")]),_c('p',[_vm._v("2024年12月")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgs"},[_c('img',{attrs:{"src":require("../assets/slideBgs2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_c('span',[_vm._v("活动流程")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"contentText"},[_c('h1',[_vm._v("· 2024-12-28 ·")]),_c('div',{staticClass:"lop"},[_c('div',{staticClass:"left"},[_c('p',[_vm._v("15:00 — 15:30")]),_c('p',[_vm._v("15:30 — 15:35")]),_c('p',[_vm._v("15:35 — 15:40")]),_c('p',[_vm._v("15:40 — 17:00")]),_c('p',[_vm._v("17:00 — 17:10")]),_c('p',[_vm._v("17:10 — 17:20")])]),_c('div',{staticClass:"center"},[_c('p',[_c('i')]),_c('p',[_c('i')]),_c('p',[_c('i')]),_c('p',[_c('i')]),_c('p',[_c('i')]),_c('p',[_c('i')])]),_c('div',{staticClass:"right"},[_c('p',[_vm._v("暖场签到")]),_c('p',[_vm._v("领导致辞")]),_c('p',[_vm._v("颁发荣誉证书")]),_c('p',[_vm._v("颁发聘书")]),_c('p',[_vm._v("授牌仪式")]),_c('p',[_vm._v("理事大合影")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgs"},[_c('img',{attrs:{"src":require("../assets/slideBgs2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_c('span',[_vm._v("历届"),_c('br'),_vm._v("就职典礼回顾")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"po1"},[_c('img',{attrs:{"src":require("../assets/po1s.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po2"},[_c('img',{attrs:{"src":require("../assets/pos2.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po3"},[_c('img',{attrs:{"src":require("../assets/pos3.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po4"},[_c('img',{attrs:{"src":require("../assets/bottom.png"),"alt":"","width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgs"},[_c('img',{attrs:{"src":require("../assets/slideBgs2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_c('span',[_vm._v("荣耀授牌 邀君莅临")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"po1"},[_c('img',{attrs:{"src":require("../assets/po17.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po2"},[_c('img',{attrs:{"src":require("../assets/po27.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po3"},[_c('img',{attrs:{"src":require("../assets/po37.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"po4"},[_c('img',{attrs:{"src":require("../assets/bottom.png"),"alt":"","width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgs"},[_c('img',{attrs:{"src":require("../assets/slideBgs2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/slide1logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_c('span',[_vm._v("时间地点报名方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs1"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/sli51.png"),"alt":"","width":"100%"}})]),_c('p',[_c('span',[_vm._v("12.28")]),_c('span',[_c('b',[_vm._v("周六")]),_c('i',[_vm._v("15:00-17:00")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cs2"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/sli52.png"),"alt":"","width":"100%"}})]),_c('p',[_vm._v("长沙世茂希尔顿酒店4楼世茂1+2厅")]),_c('p',[_vm._v("（地址:岳麓区潇湘北路三段1100号）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrcode"},[_c('img',{attrs:{"src":require("../assets/sli53.png"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('img',{attrs:{"src":require("../assets/sli56.png"),"width":"100%","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('span',[_c('img',{attrs:{"src":require("../assets/sli54.png"),"alt":"","width":"100%"}})]),_c('p',[_vm._v("如有任何疑问或需要帮助")]),_c('p',[_vm._v("请联系校友会秘书处")])])
}]

export { render, staticRenderFns }